.transparent {
  --background: transparent !important;
  background: transparent !important;
  box-shadow: none;
}

.label {
  color: var(--nameForm) !important;
}

.h-100 {
  height: 100% !important;
}

.divisorTime {
  width: 97%;
  position: absolute;
  top: 0;
  background: rgb(47, 187, 34);
  border-radius: 4px;
  opacity: 0.8;
  margin-left: 2.5px;
}

:ng-deep .item-has-focus.item-label-stacked .select-icon {
  transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
}

.np {
  padding: 0 !important;
}

.zoomContainer {
  --background: transparent;
  backdrop-filter: blur(1.5px);
}

.app.ios .header-ios {
  min-height: calc(56px + 20px);
  padding-top: 32px;
}

.app.ios ion-modal ion-content {
  --padding-top: 2rem;
  --padding-bottom: 2rem;
}

.searchbar-input-container.sc-ion-searchbar-ios {
  height: 100%;
}

.hw-100 {
  min-height: 100vh;
}

.m-auto {
  margin: auto;
}

.noShadow {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.btns-admin {
  font-size: 12px;
  text-transform: initial;
}

.alert-tappable.sc-ion-alert-md {
  min-height: 48px !important;
  contain: content !important;
  height: auto !important;
}

.ion-accordion-toggle-icon {
  color: var(--description) !important;
}

.userProof {
  --border-radius: 0.5rem;
}

.ocultBadge {
  width: 0px;
  min-width: 0;
  transition: all ease 0.5s;
  opacity: 0;
  --padding-end: 0;
  --padding-start: 0;
}

.showBadge {
  --padding-end: 8px;
  --padding-start: 8px;
  opacity: 1;
  width: 32px;
}

.boldText {
  font-weight: bold !important;
}

.noBorder {
  border: none;
}

.btn-aboutme {
  --border-radius: 12px !important;
  --box-shadow: 0px 2px 5px rgb(57 117 1 / 34%);
  border-radius: 12px !important;
  background: #fff;
  text-transform: capitalize;
  margin: 20px 0 5px 0.1rem;
  box-shadow: none;
}

@media screen and (min-width: 1000px) {
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --max-width: 35% !important;
    --min-height: 90vh;
    justify-content: right;
  }
}

@media screen and (min-width: 578px) {
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    --min-height: 90vh;
    justify-content: right;
  }
}

.more {
  font-size: 1.2rem;
  --padding-start: 1.5rem;
  --padding-end: 1.5rem;
  ion-label {
    text-transform: initial;
  }
}

.w-100 {
  flex-basis: 100%;
  width: 100%;
}

.titleCard {
  font-size: 1.5rem;
  font-weight: 800;
}

.dateEvent {
  cursor: pointer;
  position: relative;
  &::before {
    content: "X";
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
  }
}

.subtitles {
  font-size: 1.8rem;
  font-weight: 800;
}

@media screen and (min-width: 660px) {
  .titleCard {
    font-size: 2rem;
  }
}

.bgCard {
  background: var(--card, #fff);
}

.pager {
  margin: 3.5rem 0 !important;
  display: flex;
  justify-content: center;
  margin: auto;
}

.toastMsg {
  --background: var(--header);
  --color: var(--btnHeader);
  color: var(--btnHeader);
}

@media screen and (max-width: 470px) {
  .toastMsg {
    --width: 100vh;
    --border-radius: 0px;
    --min-height: 50px;
    --start: 0;
    --ion-safe-area-bottom: -11px;
  }
}

.noPadding {
  padding: 0;
}

.splash {
  --width: 100vw;
  --height: 100vh;
}

.pointer {
  cursor: pointer !important;
}

.d-flex {
  display: flex;
}

.modalRegister {
  --height: 45%;
  --min-height: 0%;
  --border-radius: 1.8rem;
  --backdrop-opacity: 0.2;
  --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
  --width: 95%;
  --background: var(--modals);
}
.toolbarUser {
  .searchbar-input {
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}

.notification {
  --min-width: 100%;
  --offset-x: 12.5px;
}

@media screen and (min-width: 578px) {
  .notification {
    --min-width: 400px;
    --backdrop-opacity: 0;
    --offset-x: -1rem;
  }
  .modalRegister {
    --height: 35%;
    --min-height: 0%;
    --border-radius: 1.8rem;
    --backdrop-opacity: 0.2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 30%;
  }

  .modalAdmin {
    --height: 60%;
    --min-height: 56%;
    --border-radius: 1.8rem;
    --backdrop-opacity: 0.2;
    --box-shadow: 0 4px 5px rgb(0 0 0 / 16%);
    --width: 55%;
  }

  .forgottPass {
    --background: transparent;
    --box-shadow: none;
  }
}

.modalColor {
  background-color: var(--modals);
  --background: var(--modals);
}

.indexSup {
  position: relative;
  z-index: 1000;
}

@media screen and (min-width: 578px) {
  .centerPopover {
    --max-height: 400px;
    --min-width: 50%;
    --offset-y: -25%;
    --backdrop-opacity: 0.2;
  }
}

.centerPopover {
  --max-height: 100%;
}

.noOpacity {
  --backdrop-opacity: 0.1;
}

.color-icon {
  --color: var(--icons);
  color: var(--icons);
}

.swiper-pagination {
  .swiper-pagination-bullet {
    margin: 0 8px !important;
    opacity: 0.5;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

ion-content {
  --background: var(--home);
}

.sections_content {
  .content_catalogue-text {
    text-align: center;
    padding-top: 1.5rem;
  }
}

.title {
  color: var(--title) !important;
  --color: var(--title) !important;
  // padding-left: 1rem;
}

@media screen and (max-width: 420px) {
  .title {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.8rem;
  }
}
.fc-toolbar-title {
  color: var(--description) !important;
}

.description {
  color: var(--description) !important;
  --color: var(--description) !important;
}

.d-block {
  display: block;
}

.fullWdModal {
  --width: 100%;
  --height: 100%;
}

.searchbar {
  .searchbar-search-icon.sc-ion-searchbar-md {
    left: auto;
    z-index: 2;
    right: 16px;
    color: #000;
  }
}

.popover-wide {
  ion-radio {
    --color-checked: var(--title) !important;
  }
}

.searchBarUser {
  border-right: 1.5px solid #aaa;
  border-left: 1.5px solid #aaa;
  .searchbar-search-icon.sc-ion-searchbar-md {
    display: none !important;
  }
}

.register-btn {
  width: fit-content;
  margin: auto;
  .border-bottom {
    border-bottom: 1px solid #fff;
  }
}

.ngx-datatable.material {
  border-radius: 0.7rem 0.7rem 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  .datatable-header {
    height: auto !important;
    .datatable-header-cell {
      background-color: #fff;
      // .sort-btn {
      //   color: var(--icons);
      // }
    }
    // .datatable-header-cell-label {
    // color: var(--btnHeader);
    // }
  }
  .datatable-body-row {
    height: auto !important;
    .datatable-body-cell {
      height: auto !important;
      border-bottom: 1px solid #ccc;
      .datatable-body-cell-label {
        span[title~="Activo"],
        span[title~="Inactivo"],
        span[title~="Verificado"],
        span[title~="No verificado"] {
          cursor: pointer;
          position: relative;
          color: #fff;
          padding: 0.5rem 1rem;
          z-index: 2;
          &:hover::before {
            opacity: 0.9;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            border-radius: 8px;
            z-index: -1;
            transition: opacity 0.5s ease;
            background-color: var(--primary--background);
          }
        }
        span[title~="Activo"] {
          &::before {
            background-color: var(--primary--background);
          }
        }
        span[title~="Inactivo"] {
          &::before {
            background-color: var(--primary--background);
          }
        }
      }
    }
  }
}

.rolSpan {
  cursor: pointer;
  position: relative;
  color: #fff;
  padding: 0.5rem 1rem;
  background: #272727;
  border-radius: 0.5rem;
  z-index: 2;
  &:hover::before {
    opacity: 0.9;
  }
  &::before {
    content: "";
    background-color: var(--primary--background);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    border-radius: 8px;
    z-index: -1;
    transition: opacity 0.5s ease;
  }
}

.popoverActivo {
  --backdrop-opacity: 0;
  --width: auto;
  --box-shadow: 0 2px 6px #40404066;
}

.checkbox {
  --color-checked: var(--title);
  --background-checked: var(--title);
  --border-color-checked: var(--buttons);
  margin-right: 0.8rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.checkboxInput {
  --background-checked: #fff;
  --checkmark-color: #272727;
  --border-color-checked: #fff;
  --background: #fff;
  --color: #000;
  --border-color: #ccc;
  margin-right: 0.8rem;
}

:root {
  .select-popover {
    .sc-ion-select-popover-md {
      white-space: normal;
    }
  }
  :ng-deep .item-has-focus {
    transform: translate3d(-10px, 0px, 0px) rotate(180deg) !important;
  }
}

.header {
  background-color: var(--header);
  background: var(--header);
}

.headerCard {
  background-color: var(--title);
}

.headerBtn {
  color: var(--btnHeader) !important;
}

.successComponent {
  --width: 90%;
  --height: 35%;
  --border-radius: 1.2rem;
  --background: var(--modals, #fff);
}

@media screen and (min-width: 769px) {
  .successComponent {
    --width: 35%;
    --height: 35%;
    --border-radius: 1.2rem;
  }
}

.select-search {
  color: #121212;
  --background: rgb(243 243 243);
  --icon-color: #121212;
  --box-shadow: inherit;
}

@media screen and (max-width: 530px) {
  .tached {
    font-size: 12px;
  }
}

.tached {
  text-decoration: line-through;
  font-size: 0.8rem;
  opacity: 0.8;
}

.fc-blocked-business {
  background-color: #00000036;
  background: var(--fc-non-business-color, rgba(215, 215, 215, 0.5));
}

.underline {
  text-decoration: underline;
}

.alert-radio-label,
.alert-checkbox-label {
  white-space: normal !important;
}

.alert-wrapper.sc-ion-alert-md,
.alert-wrapper.sc-ion-alert-ios {
  background: var(--card) !important;
  h2,
  .alert-radio-label,
  .alert-checkbox-label,
  .alert-message {
    color: var(--description) !important;
  }
}
